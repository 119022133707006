@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');
.App{
   width: 100vw;
   height: 100vh;
   background-color: aqua;
}
.sideBar{
 height: 100%;
 width: 100px;
 background-color: #0d347a;
padding-top: 1.0px;
flex: 1 0 auto;
 

}
* {
   margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
}
.navbar {
  background-color:#fff !important;

}
.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

.menu-active{
  font-weight:bold;
  border-bottom: 1px solid #565656;
}

.navbar-brand {
  font-size: x-large !important;
  color:#fff !important;

}
.menu-active {

  font-weight: bold;
  border-bottom: 1px solid #565656;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
