.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #f7f5f5;
}
.container {
  display: flex;
  flex: 1;
}
.container_home {
  display: flex;
  flex: 1;
  color: #f7f5f5;
 
}
.main {
  flex: 1;
  background: #f7f5f5;
}
.header,
.footer {
  background: #375bfd;
  height: 50px;
  color: #fc86aa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: hwb(0 0% 93% / 0.027);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #110268;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 75vh;
}

.Auth-form {
  width: 850px;
  box-shadow: rgba(6, 1, 73, 0.16) 1px 1px 5px;
  padding-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: rgb(99, 107, 214);
}

.Auth-form-content {
  padding-left: 10%;
  padding-right: 10%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(236, 236, 236);
  font-weight: 800;
}

label {
  font-size: 14px;
  margin-bottom: 1em;
  margin-left: 1em;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
.input-control-taskdesc {
margin-left: 25px;
align-items: center;
height: 80px;

}